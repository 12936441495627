import React from 'react';
import styled from 'styled-components';
import { fontWeights, fontSize, maxBreakpointQuery, minBreakpointQuery } from '../styles';
import { Link } from './ui';

const StyledCategories = styled.ul`
  display: flex;
  flex-wrap: wrap;
  ${({ alt }) => (alt ? 'margin-top: 6px' : 'margin: 30px 0 40px')};
`;

const StyledCategory = styled.li`
  margin: 2px 0;
  ${({ alt }) => (alt ? '' : 'font-weight: ' + fontWeights.medium)};

  ${maxBreakpointQuery.small`
    ${fontSize(14)};
  `};

  ${({ alt }) => !alt &&
    minBreakpointQuery.smedium`
      ${fontSize(18)}
    `
  };

  ${({ alt }) => !alt &&
    minBreakpointQuery.medium`
      ${fontSize(20)}
    `
  };

  &:after {
    content: ',';
    margin-right: 4px;
  }

  &:last-of-type:after {
    content: '';
    margin-right: 0;
  }
`;

const CategoriesList = ({ items, alt }) => (
    <StyledCategories alt={alt}>
        {items.map(category => (
            <StyledCategory alt={alt}>
                <Link to={`projects/${category.slug}`}>{category.name}</Link>
            </StyledCategory>
        ))}
    </StyledCategories>
);

export default CategoriesList;
