import React from 'react';
import { Link } from './ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  brandColours,
  fontWeights,
  fontSize,
  standardColours,
  standardTransition,
  minBreakpointQuery,
} from '../styles';
import { graphql } from 'gatsby';
import CategoriesList from './CategoriesList';

const StyledProjectCard = styled.article``;

const StyledImagePanel = styled(Link)`
  position: relative;
`;

const StyledLabel = styled.span`
  position: absolute;
  background-color: ${standardColours.transparentBlack(0.7)};
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-weight: ${fontWeights.semibold};
  ${fontSize(22)};
  color: ${standardColours.white};
  opacity: 0;
  transition: ${standardTransition('opacity')};

  ${minBreakpointQuery.medium`
    ${fontSize(25)};
  `};

  ${minBreakpointQuery.large`
    ${fontSize(28)};
  `};

  &:hover {
    opacity: 1;
  }
`;

const StyledHeading = styled.h3`
  color: ${brandColours.primary};
  font-weight: ${fontWeights.bold};
  ${fontSize(24)};
  margin-top: 15px;

  ${minBreakpointQuery.medium`
    ${fontSize(27)};
  `};

  ${minBreakpointQuery.large`
    ${fontSize(30)};
  `};
`;

const ProjectCard = ({ title, slug, image, categories }) => {
  const urlPath = `projects/${slug}`;

  return (
    <StyledProjectCard>
      <StyledImagePanel to={urlPath}>
        <GatsbyImage image={image.gatsbyImageData} alt={image.alt} />
        <StyledLabel>View Project</StyledLabel>
      </StyledImagePanel>
      <StyledHeading>
        <Link to={urlPath}>{title}</Link>
      </StyledHeading>
      {categories.length > 0 && (
        <CategoriesList items={categories} alt={true} />
      )}
    </StyledProjectCard>
  );
};

export default ProjectCard;

export const ProjectCardFragment = graphql`
  fragment ProjectCardFragment on DatoCmsProject {
    title
    slug
    image: featuredImage {
      gatsbyImageData(width: 560, height: 360)
      alt
    }
    categories {
      name
      slug
    }
  }
`;
