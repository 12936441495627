import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles';
import ProjectCard from './ProjectCard';
import { Container } from './ui';

const StyledProjectCardListing = styled.section`
  ${sectionMargins(undefined, '80px')};
`;

const StyledProjectCardList = styled(Container)`
  display: grid;
  gap: 30px 20px;

  ${minBreakpointQuery.small`
    grid-template-columns: repeat(2, 1fr);
  `};

  ${minBreakpointQuery.medium`
    row-gap: 40px;
  `};

  ${minBreakpointQuery.large`
    row-gap: 50px;
  `};

  ${minBreakpointQuery.xxlarge`
    row-gap: 60px;
  `};
`;

const ProjectCardListing = ({ items }) => (
  <StyledProjectCardListing>
    <StyledProjectCardList>
      {items.map(({ title, slug, image, categories }) => (
        <ProjectCard
          title={title}
          slug={slug}
          image={image}
          categories={categories}
        />
      ))}
    </StyledProjectCardList>
  </StyledProjectCardListing>
);

export default ProjectCardListing;
