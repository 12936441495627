import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ProjectCategoriesNav from '../components/ProjectCategoriesNav';
import ProjectCardListing from '../components/ProjectCardListing';

const ProjectsCategoryTemplate = ({
  data: {
    datoCmsProjectsCategory: { seoMetaTags, name },
    allDatoCmsProject: { nodes: projects },
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <Banner heading={name} />
      <ProjectCategoriesNav />
      <ProjectCardListing items={projects} />
    </main>
  </Layout>
);

export const ProjectsCategoryTemplateQuery = graphql`
  query ProjectsCategoryTemplateQuery($id: String!) {
    datoCmsProjectsCategory(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      name
    }
    allDatoCmsProject(
      filter: { categories: { elemMatch: { id: { eq: $id } } } }
      sort: { fields: position }
    ) {
      nodes {
        ...ProjectCardFragment
      }
    }
  }
`;

export default ProjectsCategoryTemplate;
