import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Container, Link } from './ui';
import {
  minBreakpointQuery,
  sectionMargins,
  brandColours,
  fontWeights,
  fontSize,
} from '../styles';

const StyledProjectCategoriesNav = styled.nav`
  ${sectionMargins(undefined, '80px')};
`;

const StyledList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`;

const StyledItem = styled.li`
  margin: 8px;
  font-weight: ${fontWeights.medium};

  ${minBreakpointQuery.small`
    ${fontSize(18)};
  `};
`;

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }

  &.current-page {
    color: ${brandColours.primary};
  }
`;

const ProjectCategoriesNav = () => {
  const {
    allDatoCmsProjectsCategory: { nodes: categories },
  } = useStaticQuery(graphql`
    query ProjectCategoriesNavQuery {
      allDatoCmsProjectsCategory {
        nodes {
          name
          slug
        }
      }
    }
  `);

  return (
    <StyledProjectCategoriesNav>
      <Container>
        <StyledList>
          <StyledItem>
            <StyledLink to={`projects`} activeClassName="current-page">
              Show All
            </StyledLink>
          </StyledItem>
          {categories.map(({ name, slug }) => (
            <StyledItem>
              <StyledLink
                to={`projects/${slug}`}
                activeClassName="current-page"
              >
                {name}
              </StyledLink>
            </StyledItem>
          ))}
        </StyledList>
      </Container>
    </StyledProjectCategoriesNav>
  );
};

export default ProjectCategoriesNav;
